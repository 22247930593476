import waitForObjectExistence from '@/utils/waitForObjectExistence'
import sendTrackingEvent from '@/utils/sendTrackingEvent'
import { getQueryParamsObject, getLastUrlSegment } from '@/utils/url'
import { pushDataLayerEvent } from '@/utils/datalayer'

const createCordialContact = async (values) => {
    const cordialParams = {
        utm_source: 'utm_source',
        utm_term: 'utm_term',
        utm_campaign: 'utm_campaign',
        utm_medium: 'utm_medium',
        utm_content: 'utm_content',
        firstResult: 'firstresult',
        secondResult: 'secondresult'
    }

    const currentCordialAttributes = Object.keys(getQueryParamsObject()).filter(key => Object.keys(cordialParams).includes(key))

    return waitForObjectExistence(window.crdl, 2000, 500, 'Cordial still not found after 2 seconds')
        .then((cordialResponse) => {
            try {
                const authData = {
                    email: values.email
                }

                const contactData = {
                    channels: {
                        email: {
                            subscribeStatus: 'subscribed'
                        }
                    },
                    signup_source: window.location.pathname
                }

                if (values.phoneNumber) {
                    contactData.channels.sms = {
                        'address': values.phoneNumber,
                        'programs': {
                            'promotional': {
                                'keyword': 'quiz',
                                'subscribeStatus': 'subscribed'
                            }
                        }
                    }
                }

                currentCordialAttributes.forEach(attribute => {
                    contactData[cordialParams[attribute]] = getQueryParamsObject()[attribute]
                })

                cordialResponse('contact', authData, contactData)
                // this will always be reached if authData and contactData are vaild objects (minimal risk of erorring)
                return true
            } catch (error) {
                console.error(`Failed to add contact to cordial with values: ${values}`)
                window.ineum(
                    'reportError',
                    new Error(`Failed to add contact to cordial with values: ${values}`)
                )

                return false
            }
        })
        .catch((error) => {
            console.error(`[getCordial] -- Failed to find Cordial with ${error}`)
            window.ineum(`[getCordial] -- Failed to find Cordial with ${error}`)
        })
}

const handleCordialEmailSubmission = async (email, validationHandler) => {
    const subscribeValues = {
        email: email
    }

    let signupBtn = document.activeElement
    let footer = document.querySelector('footer[class*="footer"]') || document.querySelector('footer[data-selector*="main-footer-section"]')
    let isInFooter = footer.contains(signupBtn)

    try {
        if (subscribeValues.email === '') {
            throw new Error('Please submit an email.')
        }
        const wasContactCreated = await createCordialContact(subscribeValues)

        if (wasContactCreated) {
            validationHandler && validationHandler('success', 'You\'ve been subscribed!')
            sendTrackingEvent('Email Signup', 'Email Signup Success', 'Subscribed', { metric7: 1 })
            pushDataLayerEvent({
                event: 'emailSignup',
                email: `${email}`,
                signupLocation: (isInFooter ? 'storefrontFooter' : `${getLastUrlSegment()}`)
            })
        } else {
            throw new Error('Something went wrong.')
        }
    } catch (error) {
        console.error(`There was an error creating the Cordial contact with the message: ${error.message}`)
        window?.ineum?.('subscribedUserEmailError', error, { msg: error.message })
        validationHandler('error', error.message)
    }
}

export {
    createCordialContact,
    handleCordialEmailSubmission
}