export default function waitForObjectExistence(object, timeToCheck, delayBetweenChecks, errorMessage) {
    return new Promise((resolve, reject) => {
        let checkCount = 0
        let intervalID = null

        const checkLimit = timeToCheck / delayBetweenChecks

        const checkIfObjectExists = () => {
            const hasObject = Boolean(object)
            const isCheckLimitMet = checkCount >= checkLimit

            if (hasObject) {
                resolve(object)
                clearInterval(intervalID)
            } else if (isCheckLimitMet) {
                reject(new Error(errorMessage))
                clearInterval(intervalID)
            } else {
                checkCount++
            }
        }

        intervalID = setInterval(checkIfObjectExists, delayBetweenChecks)
    })
}
