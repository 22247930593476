import * as Sentry from '@sentry/nextjs'

export const errorTagName = 'errorSource'
export const errorTagValue = 'saatva'

const sentryContext = {
    tags: {
        [errorTagName]: errorTagValue
    }
}

const handleLogs = (message, callback) => {
    console.log(message)
    if (callback) callback()
}

const handleError = (error, callback) => {
    console.error(error)
    Sentry.captureException(error, sentryContext)
    if (callback) callback()
}

const handleErrorMessage = (errorMessage, callback) => {
    console.error(`Error: ${errorMessage}`)
    Sentry.captureMessage(errorMessage, sentryContext)
    if (callback) callback()
}

/* TODO: upate to new error/logging library once in place. For now
we'll rely on stdout to capture user error rather than throwing an
application error. */
const handleUserError = (errorMessage, callback) => {
    console.error(`Error: ${errorMessage}`)
    if (callback) callback()
}

export {
    handleError,
    handleErrorMessage,
    handleUserError,
    handleLogs
}